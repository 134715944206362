@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Recoleta";
    font-weight: 100;
    src: url("./font/Recoleta-Light.ttf");
  }
  @font-face {
    font-family: "Recoleta";
    font-weight: 600;
    src: url("./font/Recoleta-Medium.ttf");
  }
  @font-face {
    font-family: "Recoleta";
    font-weight: normal;
    src: url("./font/Recoleta-Regular.ttf");
  }
  @font-face {
    font-family: "Recoleta";
    font-weight: 700;
    src: url("./font/Recoleta-SemiBold.ttf");
  }
  @font-face {
    font-family: "Recoleta";
    font-weight: bold;
    src: url("./font/Recoleta-Bold.ttf");
  }
  @font-face {
    font-family: "Recoleta";
    font-weight: 900;
    src: url("./font/Recoleta-Black.ttf");
  }
}

html,
body {
  height: 100vh !important;
  background-color: #fbf0e1;
}

#root {
  height: 100% !important;
}
.peerlist-logo {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
